import React, { useContext, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { db } from "../../firebase";
import { getDocs, collection, setDoc, doc } from "firebase/firestore";
import { useEffect } from "react";
import { applicationContext } from "../../context";
import ProfileCard from "../../Components/ProfileCard";
import "../ProfilePage/profilepage.css";
import ProfileFooter from "../../Components/ProfileFooter";
import Loader from "../../Components/Loader";
import { getDoc } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";

function ProfilePage() {
  const { setReservation, reservation, setTotalCoins, user, rides, uid } =
    useContext(applicationContext);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchAllDocs = async () => {
      const collectionRef = collection(db, "tickets2024");
      const querySnapshot = await getDocs(collectionRef);
      const docsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      const data = docsData
        .filter((data) => {
          // return data.data.userEmail.toLowerCase() === user.toLowerCase();
          return data.data.userID === uid;
        })
        .sort((a, b) => Date.parse(b.data.date) - Date.parse(a.data.date))
        .slice(0, 30);
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);
      const docsData2 = docSnap.data();

      setReservation(data);

      setTotalCoins(docsData2.coins + docsData2.freeCoins);
    };

    fetchAllDocs();
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <div className="profile-page">
      <Header />
      {isLoading ? <Loader height={"84vh"} /> : <ProfileCard />}
      <ProfileFooter />
    </div>
  );
}

export default ProfilePage;
