import * as React from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Checkbox, Popover } from "@mui/material";

export const CheckIn = ({ checked, clickHandler }) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickOpen = (event) => {
    if(checked) return;
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    clickHandler();
    setOpen(false);
  };

  return (
    <div>
      <Checkbox
        onChange={handleClickOpen}
        checked={checked}
        color="warning"
      />
      <Popover
      anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"CONFIRM CHECKIN"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action can't be reverted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>NO</Button>
          <Button color="error" onClick={handleClick} autoFocus>
            YES
          </Button>
        </DialogActions>
      </Popover>
    </div>
  );
};
