import * as React from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Popover } from "@mui/material";

export const DeleteButton = ({ deleteHandler }) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    deleteHandler();
    setOpen(false);
  };

  return (
    <div>
      <Button
        className="del"
        variant="contained"
        color= "error"
        onClick={handleClickOpen}
        style={{ padding: ".5rem" }}
      >
        Delete
      </Button>
      <Popover
      anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"CONFIRM DELETION"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action can't be reverted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>NO</Button>
          <Button color="error" onClick={handleDelete} autoFocus>
            YES
          </Button>
        </DialogActions>
      </Popover>
    </div>
  );
};
