import React from "react";
import "../LoginHeader/loginheader.css";
function LogInHeader() {
  return (
    <div className="log-in-header">
      <h3 style={{ fontFamily: "Gagalin" }}>Log In</h3>
    </div>
  );
}

export default LogInHeader;
